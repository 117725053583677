<template>
  <div class="py-10">
    <!-- search input -->
    <v-row
      justify="space-between"
      no-gutters>
      <v-col
        class="d-flex"
        cols="8">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          hide-details
          filled
          rounded
          label="Search for an exercise"
          dense
          class="mr-5"
          @focus="$emit('initSearch')">
        </v-text-field
        ><v-btn
          depressed
          color="primaryColor"
          rounded
          class="filter-btn px-3"
          @click="$emit('initSearch', true)">
          <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
          <span class="text-uppercase">Filters</span>
        </v-btn>
      </v-col>
    </v-row>
    <div
      v-if="isLoading"
      class="mt-12 px-7">
      <v-progress-linear
        indeterminate
        color="primaryColor"></v-progress-linear>
    </div>
    <!-- popular Categories -->
    <div
      v-if="popularCategories && popularCategories.length && !isLoading"
      class="mb-8">
      <h2 class="section-title mt-8 mb-6">Most Popular Categories</h2>
      <div class="categories-grid-wrapper">
        <CategoryItem
          v-for="category in popularCategories"
          @click="goToCategoryCourses(category.id)"
          :key="category.id"
          class="category-item"
          :name="category.name"
          :cover="category.coverUrl" />
      </div>
    </div>
    <div
      v-if="unpopularCategories && unpopularCategories.length && !isLoading"
      class="mb-8">
      <h2 class="section-title mt-8 mb-6">More Categories</h2>
      <div class="categories-grid-wrapper">
        <CategoryItem
          class="category-item"
          v-for="category in unpopularCategories"
          @click="goToCategoryCourses(category.id)"
          :key="category.id"
          :name="category.name"
          :cover="category.coverUrl" />
      </div>
    </div>
  </div>
</template>

<script>
  import ExplorerScreenMixin from '@/components/courses/explorer-screen/ExplorerScreen.mixin.vue';
  export default {
    mixins: [ExplorerScreenMixin],
  };
</script>

<style lang="scss" scoped>
  .filter-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    height: unset !important;
    .v-image {
      filter: brightness(0) invert(1);
    }
  }
  .section-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  .categories-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 160px);
    gap: 16px;
  }
</style>
